/* eslint-disable consistent-return */
/* eslint-disable react/jsx-props-no-spreading */
import { useState, useEffect, useContext, useRef } from 'react';
import { useForm } from 'react-hook-form';
import editPersonalStyles from './EditPersonalInformation.module.scss';
import signUpFormStyles from '../../../Forms/Forms.module.scss';
import signUpFormScopedStyles from '../../../Forms/SignUpForm/SignUpForm.module.scss';
import profileIcon from '../../../../img/profile_signup.svg';
import { updateMyProfile } from '../../../../api/profile';
import { uploadAvatar } from '../../../../api/utils';
import countries from '../../../../utils/countries';
import withToast from '../../../HOC/withToast';
import { TOAST_TYPE } from '../../../../utils/constants';
import { UserProfileContext } from '../../../../store/Context';
import { useClickOutside } from '../../../hooks/useClickOutside';
import { phoneNumberCodes } from '../../../../utils/phoneNumberCodes';

import iconUp from '../../../../img/icon_down.svg';
import iconDown from '../../../../img/icon_down1.svg';
import { useAlert } from '../../../hooks/useAlert/useAlert';
import { checkImageSize } from '../../../../utils/function';

function EditPersonalInformation({ showToast, onProfileUpdate }) {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    setFocus,
    setError,
    clearErrors
  } = useForm({
    defaultValues: {
      upload: '',
      nickname: '',
      email: '',
      firstname: '',
      lastname: '',
      phone: '',
      company: '',
      address1: '',
      address2: '',
      town: '',
      state: '',
      zipcode: '',
      country: '',
      pass: '',
      retype: ''
    },
    mode: 'all'
  });

  const { userProfile } = useContext(UserProfileContext) || {};

  // refs
  const phoneCodeRef = useRef(null);
  const countryRef = useRef(null);

  // states for input[type='file']
  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState(userProfile?.avatar || profileIcon);

  const [telCodeSelectIsOpen, setTelCodeSelectIsOpen] = useState(false);
  const [selectedTelCode, setSelectedTelCode] = useState('+82');

  const [country, setCountry] = useState('');
  const [searchCountry, setSearchCountry] = useState('');
  const [countrySelectIsOpen, setCountrySelectIsOpen] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { onAlert } = useAlert();

  useClickOutside(phoneCodeRef, () => {
    setTelCodeSelectIsOpen(false);
  });

  useClickOutside(countryRef, () => {
    setCountrySelectIsOpen(false);
  });

  const setupData = () => {
    setPreview(userProfile?.avatar || profileIcon);
    setValue('nickname', userProfile?.nickName);
    setValue('email', userProfile?.email);
    setValue('firstname', userProfile?.firstName);
    setValue('lastname', userProfile?.lastName);
    setValue('phone', userProfile?.phoneNumber);
    setValue('company', userProfile?.companyName);
    setValue('address1', userProfile?.address1);
    setValue('address2', userProfile?.address2);
    setValue('town', userProfile?.city);
    setValue('state', userProfile?.stateRegion);
    setValue('zipcode', userProfile?.postalCode);
    setValue('country', userProfile?.country);
    setCountry(userProfile?.country);
    setValue('pass', '');
    setValue('retype', '');
  };

  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }

    checkImageSize(e.target.files[0], 320, 320)
      .then((result) => {
        setSelectedFile(e.target.files[0]);
      })
      .catch((error) => {
        onAlert('', 'OK', error.message);
      });
  };

  const handleTelBtnClick = () => {
    setTelCodeSelectIsOpen(!telCodeSelectIsOpen);
  };

  const handleSelectedTelChange = (e) => {
    setSelectedTelCode(e.target.value);
    setTelCodeSelectIsOpen(false);
  };

  const handleCountryChange = (e) => {
    setCountry(e.target.value);

    setValue('country', e.target.value);
    clearErrors('country');
  };

  const handleSelectCountry = (e) => {
    setCountry(e.target.value);
    setValue('country', e.target.value);
    setCountrySelectIsOpen(false);
    setSearchCountry('');
    clearErrors('country');
  };

  const handleOnSubmit = async (data) => {
    setIsSubmitting(true);

    let avatarSrc = '';
    const avatar = new FormData();

    if (data.upload[0] !== undefined) {
      avatar.append('file', data.upload[0]);

      avatarSrc = await uploadAvatar(avatar);
    }

    let dataUser = {
      ...(data.nickname && { nickName: data.nickname }),
      ...(country && { country: data.country }),
      ...(data.address1 && { address1: data.address1 }),
      ...(data.address2 && { address2: data.address2 }),
      ...(data.zipcode && { postalCode: data.zipcode }),
      ...(data.pass && { password: data.pass }),
      ...(data.town && { city: data.town }),
      ...(avatarSrc && { avatar: avatarSrc }),
      ...(data.state && { stateRegion: data.state }),
      ...(data.phone && { phoneNumber: data.phone }),
      companyName: data.company
    };

    // validate user change password
    if (data.pass) {
      const numberRegex = /\d/;
      const lettersRegex = /[a-zA-Z]/g;
      const specialCharacterRegex = /[-!@#$%^&*()_+|~=`{}[\]:";'<>?,./]/g;
      if (data.pass.length < 8) {
        setError('pass', {
          type: 'invalid',
          message: 'Your password should include at least 8 characters.'
        });
        setFocus('pass');
        setIsSubmitting(false);
        return;
      }
      if (!numberRegex.test(data.pass) || !lettersRegex.test(data.pass)) {
        setError('pass', {
          type: 'invalid',
          message: 'Your password should include letters and numbers.'
        });
        setFocus('pass');
        setIsSubmitting(false);
        return;
      }
      if (!specialCharacterRegex.test(data.pass)) {
        setError('pass', {
          type: 'invalid',
          message:
            "Your password should include at least one special character.'"
        });
        setFocus('pass');
        setIsSubmitting(false);
        return;
      }
      if (data.retype) {
        if (data.retype === data.pass) {
          dataUser = { ...dataUser, password: data.pass };
        } else {
          setError('retype', {
            type: 'invalid',
            message: 'Passwords don’t match.'
          });
          setFocus('retype');
          setIsSubmitting(false);
          return;
        }
      } else {
        setError('retype', {
          type: 'invalid',
          message: 'Please confirm password.'
        });
        setFocus('retype');
        setIsSubmitting(false);
        return;
      }
    }

    // update user

    try {
      const newUserData = await updateMyProfile(dataUser);
      onProfileUpdate(newUserData.data);

      showToast(TOAST_TYPE.SUCCESS, 'Update successful');

      setValue('pass', '');
      setValue('retype', '');
      setIsSubmitting(false);
    } catch (error) {
      setIsSubmitting(false);
      showToast(TOAST_TYPE.ERROR, 'Update failed');
    }
  };

  useEffect(() => {
    setupData();
  }, [userProfile]);

  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!selectedFile || !preview.length) {
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  return (
    <section className={signUpFormStyles.form_section}>
      <form
        onSubmit={handleSubmit(handleOnSubmit)}
        className={signUpFormStyles.form}>
        <h1 className={signUpFormStyles.title}>EDIT PERSONAL INFORMATION</h1>
        <div className={signUpFormStyles.form_container}>
          <div className={signUpFormScopedStyles.fieldset_container}>
            <p className={signUpFormScopedStyles.data_title}>Profile</p>
            <fieldset className={signUpFormScopedStyles.fieldset}>
              <label
                className={signUpFormScopedStyles.label_upload}
                htmlFor="upload">
                <div className={signUpFormScopedStyles.profile_icon}>
                  <img
                    className={signUpFormScopedStyles.profile_icon}
                    src={preview}
                    alt="profile icon"
                  />
                </div>
                <div className={signUpFormScopedStyles.upload_container}>
                  <input
                    {...register('upload')}
                    onChange={onSelectFile}
                    className={signUpFormScopedStyles.input_upload}
                    type="file"
                    name="upload"
                    accept=".png, .jpg, .gif"
                  />
                  <p className={signUpFormScopedStyles.profile_desc}>
                    You can upload files by PNG, JPG, GIF.
                  </p>
                  <p className={signUpFormScopedStyles.profile_desc_info}>
                    Image size should be at least 320 x 320 pixels.
                  </p>
                </div>
              </label>
              <label
                className={signUpFormScopedStyles.label_input_fieldset}
                htmlFor="nickname">
                <p>Nickname</p>
                <input
                  {...register('nickname', { required: 'Enter username.' })}
                  className={signUpFormScopedStyles.profile_nickname}
                  type="text"
                  name="nickname"
                  onChange={(e) => {
                    const { value } = e.target;
                    if (value.length <= 14) {
                      setValue('nickname', value);
                      clearErrors('nickname');
                    } else {
                      e.target.value = value.substring(0, 14);
                    }
                  }}
                />
              </label>
              <p className={signUpFormScopedStyles.validation_warn}>
                {errors.nickname?.message}
              </p>
            </fieldset>
          </div>
          <label
            className={signUpFormScopedStyles.label_input}
            style={{ marginBottom: '40px' }}
            htmlFor="email">
            <div className={editPersonalStyles.filed_name}>
              <p className={signUpFormScopedStyles.data_title}>Email</p>
              <p className={signUpFormScopedStyles.data_title}>
                Cannot be modified
              </p>
            </div>
            <input {...register('email')} type="email" name="email" disabled />
            <p className={signUpFormScopedStyles.validation_warn}>
              {errors.email?.message}
            </p>
          </label>
          <div className={signUpFormScopedStyles.fieldset_container}>
            <p
              className={signUpFormScopedStyles.data_title}
              style={{ marginBottom: '4px' }}>
              Password
            </p>
            <span className={signUpFormScopedStyles.pass_descr}>
              Please use at least 8 characters, a combination of letters,
              numeric and special characters.
            </span>
            <fieldset className={signUpFormScopedStyles.fieldset}>
              <label
                className={signUpFormScopedStyles.label_input_fieldset}
                htmlFor="pass">
                <p>Password</p>
                <input
                  {...register('pass')}
                  type="password"
                  name="pass"
                  autoComplete="new-password"
                  onChange={(e) => {
                    const { value } = e.target;
                    if (value.length <= 15) {
                      setValue('pass', e.target.value);
                      clearErrors('pass');
                    } else {
                      e.target.value = value.substring(0, 15);
                    }
                  }}
                />
              </label>
              <label
                className={signUpFormScopedStyles.label_input_fieldset}
                style={{ borderTop: '1px solid #ededed' }}
                htmlFor="retype">
                <p>Retype password</p>
                <input
                  {...register('retype')}
                  type="password"
                  name="retype"
                  onChange={(e) => {
                    const { value } = e.target;
                    if (value.length <= 15) {
                      setValue('retype', e.target.value);
                      clearErrors('retype');
                    } else {
                      e.target.value = value.substring(0, 15);
                    }
                  }}
                />
              </label>
              <p className={signUpFormScopedStyles.validation_warn}>
                {errors.pass?.message} {errors.retype?.message}
              </p>
            </fieldset>
          </div>
          <div className={signUpFormScopedStyles.fieldset_container}>
            <div className={editPersonalStyles.filed_name}>
              <p className={signUpFormScopedStyles.data_title}>Name</p>
              <p className={signUpFormScopedStyles.data_title}>
                Cannot be modified
              </p>
            </div>
            <fieldset className={signUpFormScopedStyles.fieldset}>
              <label
                className={signUpFormScopedStyles.label_input_fieldset}
                htmlFor="firstname">
                <p>First name</p>
                <input
                  {...register('firstname')}
                  type="text"
                  name="firstname"
                  readOnly
                />
              </label>
              <label
                className={signUpFormScopedStyles.label_input_fieldset}
                style={{ borderTop: '1px solid #ededed' }}
                htmlFor="lastname">
                <p>Last name</p>
                <input
                  {...register('lastname')}
                  type="text"
                  name="lastname"
                  readOnly
                />
              </label>
              <p className={signUpFormScopedStyles.validation_warn}>
                {errors.firstname?.message} {errors.lastname?.message}
              </p>
            </fieldset>
          </div>
          <fieldset
            className={`${signUpFormScopedStyles.mobile_fieldset} ${signUpFormScopedStyles.fieldset}`}>
            <p>Mobile Phone</p>
            <div className={signUpFormScopedStyles.mobile_controls}>
              <div
                className={signUpFormScopedStyles.mobile_select}
                ref={phoneCodeRef}>
                <button
                  onClick={handleTelBtnClick}
                  className={signUpFormScopedStyles.custom_select_btn}
                  type="button">
                  <span>{selectedTelCode}</span>
                  <img
                    src={iconDown}
                    alt="arrow"
                    style={
                      telCodeSelectIsOpen ? { transform: 'rotate(180deg)' } : {}
                    }
                  />
                </button>
                <select
                  onChange={(e) => handleSelectedTelChange(e)}
                  className={signUpFormScopedStyles.custom_select}
                  name="mobile_code"
                  style={telCodeSelectIsOpen ? { display: 'flex' } : {}}
                  multiple>
                  {phoneNumberCodes.map((phoneCode) => (
                    <option key={phoneCode.id} value={phoneCode.value}>
                      {phoneCode.text.toLowerCase()}
                    </option>
                  ))}
                </select>
              </div>
              <input
                {...register('phone', { required: 'Enter mobile phone.' })}
                className={signUpFormScopedStyles.mobile_input}
                type="text"
                name="phone"
                onInput={(e) => {
                  const phoneValue = e.target.value
                    .split('')
                    .filter((value) => Number.isInteger(+value))
                    .join('')
                    .replace(/\s/g, '');

                  setValue('phone', phoneValue);
                }}
                onWheel={(e) => e.target.blur()}
              />
              <p className={signUpFormScopedStyles.validation_warn}>
                {errors.phone?.message}
              </p>
            </div>
          </fieldset>
          <label
            className={signUpFormScopedStyles.label_input}
            htmlFor="company">
            <p>Company name</p>
            <input {...register('company')} type="text" name="company" />
          </label>
          <label
            className={signUpFormScopedStyles.label_input}
            htmlFor="address1">
            <p>Address 1</p>
            <input
              {...register('address1', { required: 'Enter address 1.' })}
              type="text"
              name="address1"
            />
            <p className={signUpFormScopedStyles.validation_warn}>
              {errors.address1?.message}
            </p>
          </label>
          <label
            className={signUpFormScopedStyles.label_input}
            htmlFor="address2">
            <p>Address 2</p>
            <input
              {...register('address2', { required: 'Enter address 2.' })}
              type="text"
              name="address2"
            />
            <p className={signUpFormScopedStyles.validation_warn}>
              {errors.address2?.message}
            </p>
          </label>
          <label className={signUpFormScopedStyles.label_input} htmlFor="town">
            <p>Town / City</p>
            <input
              {...register('town', { required: 'Enter town / city' })}
              type="text"
              name="town"
            />
            <p className={signUpFormScopedStyles.validation_warn}>
              {errors.town?.message}
            </p>
          </label>
          <label className={signUpFormScopedStyles.label_input} htmlFor="state">
            <p>State / Province / Region</p>
            <input
              {...register('state', {
                required: 'Enter state / province / region.'
              })}
              type="text"
              name="state"
            />
            <p className={signUpFormScopedStyles.validation_warn}>
              {errors.state?.message}
            </p>
          </label>
          <label
            className={signUpFormScopedStyles.label_input}
            htmlFor="zipcode">
            <p>ZIP / Postal Code</p>
            <input
              {...register('zipcode', { required: 'Enter zip / postal code.' })}
              type="text"
              name="zipcode"
              onInput={(e) => {
                const zipCodeValue = e.target.value
                  .split('')
                  .filter((value) => Number.isInteger(+value))
                  .join('')
                  .replace(/\s/g, '');

                setValue('zipcode', zipCodeValue);
              }}
            />
            <p className={signUpFormScopedStyles.validation_warn}>
              {errors.zipcode?.message}
            </p>
          </label>
          <fieldset
            className={`${signUpFormScopedStyles.country_fieldset} ${signUpFormScopedStyles.fieldset}`}
            htmlFor="country"
            ref={countryRef}>
            <p>Country</p>
            <div className={signUpFormScopedStyles.dropdown}>
              <input
                {...register('country', { required: 'Choose country.' })}
                onClick={() => setCountrySelectIsOpen(!countrySelectIsOpen)}
                // onChange={handleCountryChange}
                onChange={(e) => setSearchCountry(e.target.value)}
                name="country"
                type="text"
                className={signUpFormScopedStyles.country_input}
                value={countrySelectIsOpen ? searchCountry : country}
                placeholder={
                  countrySelectIsOpen && !searchCountry
                    ? country
                    : 'Choose country'
                }
                style={
                  countrySelectIsOpen
                    ? {
                        outline: '1px solid #0046a6',
                        backgroundImage: `url(${iconUp})`
                      }
                    : {
                        backgroundImage: `url(${iconDown})`
                      }
                }
              />
            </div>
            <p className={signUpFormScopedStyles.validation_warn}>
              {errors.country?.message && !country
                ? errors.country?.message
                : null}
            </p>
            {countrySelectIsOpen && (
              <select name="country" multiple onChange={handleSelectCountry}>
                {searchCountry.length > 0
                  ? countries
                      .filter((countryData) =>
                        countryData.name
                          .toLowerCase()
                          .includes(searchCountry.toLowerCase())
                      )
                      .map((countryData, index) => (
                        <option key={index} value={countryData.name}>
                          {countryData.name}
                        </option>
                      ))
                  : countries.map((countryData, index) => (
                      <option key={index} value={countryData.name}>
                        {countryData.name}
                      </option>
                    ))}
              </select>
            )}
          </fieldset>
          <div
            className={signUpFormScopedStyles.btn_container}
            style={{ justifyContent: 'center' }}>
            <button
              disabled={isSubmitting || Object.keys(errors).length > 0}
              className={signUpFormScopedStyles.register_btn}
              type="submit">
              UPDATE
            </button>
          </div>
        </div>
      </form>
    </section>
  );
}

export default withToast(EditPersonalInformation);
